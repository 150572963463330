var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',[_c('f7-navbar',{attrs:{"back-link":"","title":"الورث"}}),_vm._v(" "),_c('f7-toolbar',{attrs:{"bottom":"","tabbar":""}},[_c('f7-link',{attrs:{"tab-link":"#tab-1","tab-link-active":""}},[_vm._v("ابوي")]),_vm._v(" "),_c('f7-link',{attrs:{"tab-link":"#tab-2"}},[_vm._v("امي")])],1),_vm._v(" "),_c('f7-tabs',[_c('f7-tab',{staticClass:"page-content",attrs:{"id":"tab-1","tab-active":""}},[_c('f7-block',{staticStyle:{"direction":"rtl"}},[_c('h2',[_vm._v("تقسيم الورث من ابوي")]),_vm._v(" "),_c('f7-list',{attrs:{"inline-labels":"","no-hairlines-md":""}},[_c('f7-list-input',{attrs:{"type":"text","placeholder":"ادخل المبلغ","pattern":"[0-9]*"},on:{"input":_vm.father_input}},[_c('f7-icon',{attrs:{"slot":"media"},slot:"media"},[_c('i',{staticClass:"f7-icons size-14"},[_vm._v("hand_point_left_fill")])])],1)],1)],1),_vm._v(" "),_c('f7-block-title',{staticClass:"rtl"},[_vm._v("التقسيمة")]),_vm._v(" "),_c('f7-block',{staticClass:"rtl",attrs:{"strong":""}},[_c('f7-row',[_c('f7-col',{staticClass:"wrth-th"},[_vm._v("الوقف:")]),_vm._v(" "),_c('f7-col',{staticClass:"wrth-td",domProps:{"textContent":_vm._s(new Intl.NumberFormat('ar', {
  style: 'currency',
  currency: 'SAR',
  minimumFractionDigits: 2
}).format(_vm.father.w8f))}})],1),_vm._v(" "),_c('f7-row',[_c('f7-col',{staticClass:"wrth-th"},[_vm._v("الام:")]),_vm._v(" "),_c('f7-col',{staticClass:"wrth-td",domProps:{"textContent":_vm._s(new Intl.NumberFormat('ar', {
  style: 'currency',
  currency: 'SAR',
  minimumFractionDigits: 2
}).format(_vm.father.mother))}})],1),_vm._v(" "),_c('f7-row',[_c('f7-col',{staticClass:"wrth-th"},[_vm._v("الذكر:")]),_vm._v(" "),_c('f7-col',{staticClass:"wrth-td",domProps:{"textContent":_vm._s(new Intl.NumberFormat('ar', {
  style: 'currency',
  currency: 'SAR',
  minimumFractionDigits: 2
}).format(_vm.father.male))}})],1),_vm._v(" "),_c('f7-row',[_c('f7-col',{staticClass:"wrth-th"},[_vm._v("الانثى:")]),_vm._v(" "),_c('f7-col',{staticClass:"wrth-td",domProps:{"textContent":_vm._s(new Intl.NumberFormat('ar', {
  style: 'currency',
  currency: 'SAR',
  minimumFractionDigits: 2
}).format(_vm.father.female))}})],1)],1)],1),_vm._v(" "),_c('f7-tab',{staticClass:"page-content",attrs:{"id":"tab-2"}},[_c('f7-block',{staticStyle:{"direction":"rtl"}},[_c('h2',[_vm._v("تقسيم الورث من امي")]),_vm._v(" "),_c('f7-list',{attrs:{"inline-labels":"","no-hairlines-md":""}},[_c('f7-list-input',{attrs:{"type":"text","placeholder":"ادخل المبلغ","pattern":"[0-9]*","value":_vm.mother.total},on:{"input":_vm.mother_input}},[_c('f7-icon',{attrs:{"slot":"media"},slot:"media"},[_c('i',{staticClass:"f7-icons size-14"},[_vm._v("hand_point_left_fill")])])],1)],1)],1),_vm._v(" "),_c('f7-block-title',{staticClass:"rtl"},[_vm._v("التقسيمة")]),_vm._v(" "),_c('f7-block',{staticClass:"rtl",attrs:{"strong":""}},[_c('f7-row',[_c('f7-col',{staticClass:"wrth-th"},[_vm._v("الذكر:")]),_vm._v(" "),_c('f7-col',{staticClass:"wrth-td",domProps:{"textContent":_vm._s(new Intl.NumberFormat('ar', {
  style: 'currency',
  currency: 'SAR',
  minimumFractionDigits: 2
}).format(_vm.mother.male))}})],1),_vm._v(" "),_c('f7-row',[_c('f7-col',{staticClass:"wrth-th"},[_vm._v("الانثى:")]),_vm._v(" "),_c('f7-col',{staticClass:"wrth-td",domProps:{"textContent":_vm._s(new Intl.NumberFormat('ar', {
  style: 'currency',
  currency: 'SAR',
  minimumFractionDigits: 2
}).format(_vm.mother.female))}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }