
import HomePage from '../pages/home.vue';
import SNS from '../pages/sns.vue';
import Wrth from '../pages/wrth.vue';
import Al6reeg from '../pages/al6reeg.vue';
import Alashum from '../pages/ashum.vue';


//export let api = "//localhost:3000/api"
export let api = "/api"

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/sns',
    component: SNS,
  },
  {
    path: '/wrth',
    component: Wrth,
  },
  {
    path: '/al6reeg',
    component: Al6reeg,
  },
  {
    path: '/ashum/',
    component: Alashum,
  },
];

export default routes;
