<template>
<f7-page>
  <f7-navbar back-link>امازون الى ارامكس</f7-navbar>
  <f7-list inline-labels no-hairlines-md>
    <f7-list-input
        type="textarea"
        placeholder="شير الرابط من امازون ولصقه هنا"
        @input="on_input"
        clear-button
    >
      <f7-icon slot="media">
        <i class="material-icons">pageview</i>
      </f7-icon>
    </f7-list-input>
  </f7-list>
  <f7-button :disabled="isDisabled" @click="calculate_shipping" fill round class="button-raised ws6 n9">
    شيك الشحن
  </f7-button>
  <div v-if="loading">
    <h1 style="text-align: center">Loading</h1>
  </div>
  <div v-if="response && !loading">
  <f7-block-title>Shipment info</f7-block-title>
  <f7-block strong>
    <f7-row>
      <f7-col>Weight:</f7-col>
      <f7-col v-text="`${response.weight} pounds`"></f7-col>
    </f7-row>
    <f7-row>
      <f7-col>Size:</f7-col>
      <f7-col v-text="`${response.box.length} x ${response.box.width} x ${response.box.height} ${response.box.unit}`"></f7-col>
    </f7-row>
    <f7-row v-if="response.price">
      <f7-col>Price:</f7-col>
      <f7-col v-text="response.price"></f7-col>
    </f7-row>
    <f7-row v-if="response.snsShipping">
      <f7-col>Shipping Regular:</f7-col>
      <f7-col v-text="response.snsShipping"></f7-col>
    </f7-row>
    <f7-row>
      <f7-col>Shipping with Flex:</f7-col>
      <f7-col v-text="response.snsFlexShipping"></f7-col>
    </f7-row>
    <f7-row v-if="response.vat">
      <f7-col>VAT:</f7-col>
      <f7-col v-text="response.vat"></f7-col>
    </f7-row>
    <f7-row v-if="response.total">
      <f7-col>Total:</f7-col>
      <f7-col v-text="response.total"></f7-col>
    </f7-row>
  </f7-block>
  </div>
</f7-page>
</template>

<script>
import {api} from "../js/routes";
// {"weight":"0.35","box":{"length":"5.9","width":"0.5","height":"0.5","unit":"inches"},"snsShipping":"58 SAR","snsFlexShipping":"31 SAR"}
export default {
  name: "sns",
  data() {
    return {
      url: "",
      type: "unknown",
      response: null,
      loading: false,
    }
  },
  methods: {
    on_input(e) {
      let value = e.target.value

      let url = value.match(/(https?:\/\/[^\s]+)/)
      if(url)
        this.url = url[1]
      //this.url = e.target.value
      if(/amazon/i.test(value))
      {
        this.type = "Amazon"
      }
      else
      if(/ebay\.com/i.test(value))
      {
        this.type = "eBay"
      }
      else this.type = "Unknown"
    },
    async calculate_shipping() {
      let self = this
      if(this.type.toLowerCase() === "amazon") {
        let json = JSON.stringify({
          url: this.url
        })
        this.loading = true;
        let response = await fetch(`${api}/amazon`, {
          method: 'post',
          headers: {'content-type': "application/json"},
          body: json
        }).then(response => response.json()).catch(e=>{
          self.$f7.dialog.alert(`Error: ${e.message}`)
        })
        if(response.price)
        {
          let priceInSar = response.price.match(/([0-9.]+)/)[1]
          let ShippingInSar = response.snsShipping.match(/([0-9.]+)/)[1]
          response.vat = (priceInSar*0.15).toFixed(2)
          response.total = (priceInSar/1+response.vat/1+ShippingInSar/1).toFixed(2) + " SAR"
        }

        self.response = response
        self.loading = false
      }
    }
  },
  computed: {
    isDisabled() {
      return (this.type.toLowerCase() === "unknown")
    },
    shippingIcon() {
      if(this.type.toLowerCase() === "amazon")
      {

      }else if(this.type.toLowerCase() === "ebay")
      {

      }
    }
  }
}
</script>

<style scoped>

</style>