<template>
  <f7-app :params="f7params" :theme-dark="darkMode">

  <!-- Your main view, should have "view-main" class -->
  <f7-view main class="safe-areas" url="/"></f7-view>

  </f7-app>
</template>
<script>

  import routes from '../js/routes.js';

  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'My Tools', // App name
          theme: 'auto', // Automatic theme detection



          // App routes
          routes: routes,

        },
        view: {
          pushState: true,
          history: true,
        },

        darkMode: true,

      }
    },
    methods: {
      enableDarkTheme(bit) {
        this.darkMode = bit
      }
    },

    mounted() {
      this.$f7ready((f7) => {
        window.setDark = this.enableDarkTheme
        f7.enableAutoDarkTheme()
        // Call F7 APIs here
      });
    }
  }
</script>