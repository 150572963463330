<template>
  <f7-page name="home">
    <!-- Top Navbar -->
    <f7-navbar large>
      <f7-nav-title>ادوات M7ammad</f7-nav-title>
      <f7-nav-title-large>Tools.m7ammad.com</f7-nav-title-large>
    </f7-navbar>
    <!-- Toolbar-->
<!--
    <f7-toolbar bottom>
      <f7-link>Left Link</f7-link>
      <f7-link>Right Link</f7-link>
    </f7-toolbar>
-->
    <!-- Page content-->
    <f7-block inset>

      <f7-block-header>
        ادوات احس اني احتاجهن
      </f7-block-header>
      <f7-list>
        <f7-list-item link="/sns"
                      title="حساب شحن شوب اند شيب"></f7-list-item>
        <f7-list-item link="/wrth"
                      title="الورث"
        ></f7-list-item>
        <f7-list-item link="/al6reeg"
                      title="تقييم المسافة والسرعة"
        ></f7-list-item>
        <f7-list-item link="/ashum/"
                      title="الاسهم"
        ></f7-list-item>
      </f7-list>
    </f7-block>

    <h4 style="text-align: center; margin-top: 200px">
      الباقي اذا احتجته
    </h4>

  </f7-page>
</template>

<script>
export default {
  methods: {
    setDarkTheme(bit) {
      this.$f7.enableAutoDarkTheme()
    },
    test(e)
    {
      window.setDark(e.target.checked)
    }
  },
  mounted() {
    let self = this

  }
}
</script>
