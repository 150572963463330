<template>
<f7-page name="Ashum">
  <f7-navbar back-link>الاسهم</f7-navbar>


  <f7-block class="center text-align-center">

    <span class="display-block">عدد الاسهم</span>
    <f7-input
        type="number"
        pattern="[0-9\.]+"
        v-bind:value="quantity"
        @change="quantity = parseInt($event.target.value)"
        class="ws6 n9 my-input"
        ></f7-input>
    <f7-stepper raised fill :max="2000" :min="0"  :manual-input-mode="true" :value="quantity" @stepper:change="setQuantity"></f7-stepper>
    <f7-range
        :min="0"
        :max="1000"
        :label="true"
        :step="1"
        v-bind:value="quantity"
        :scale="true"
        :scale-steps="5"
        :scale-sub-steps="4"
        @range:change="setQuantity"
    ></f7-range>



    <span class="display-block">سعر الشراء</span>
    <f7-stepper color="orange" raised fill :max="5000" :min="0" :wraps="true" :value="price_in" :manual-input-mode="true" :decimal-point="2"  @stepper:change="setPriceIn"></f7-stepper>
    <f7-range
        color="orange"
        :min="0"
        :max="1000"
        :label="true"
        :step="1"
        v-bind:value="price_in"
        :scale="true"
        :scale-steps="5"
        :scale-sub-steps="4"
        @range:change="setPriceIn"
    ></f7-range>

    <span class="display-block">سعر البيع</span>
    <f7-stepper color="purple" raised fill :max="5000" :min="0" :wraps="true" :value="price_out" :manual-input-mode="true" :decimal-point="2"  @stepper:change="setPriceOut"></f7-stepper>
    <f7-range
        color="purple"
        :min="0"
        :max="1000"
        :label="true"
        :step="1"
        v-bind:value="price_out"
        :scale="true"
        :scale-steps="5"
        :scale-sub-steps="4"
        @range:change="setPriceOut"
    ></f7-range>

<br><br>
    <table style="text-align: center" class="data-table">
      <thead>
      <tr>
        <th>التكلفة</th>
        <th>نسبة العائد</th>
        <th>صافي الربح</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td v-text="tklfh"></td>
        <td :style="`color: ${getColor}`" v-text="percentage+'%'"></td>
        <td :style="`color: ${getColor}`" v-text="gain"></td>
      </tr>
      </tbody>
    </table>


  </f7-block>
<!--
<f7-button @click="calculate">Calculate</f7-button>
-->
  <hr>
  <p class="text-align-center">التعديل حسب سعر التكلفة أو صافي الربح</p>
<f7-row>
  <f7-col>
    <f7-list inset>
      <f7-list-input
          placeholder="سعر تكلفة جديد"
          type="number"
          pattern="[0-9.]+"
          @keyup.native="newvals.tklfh = $event.target.value"
      >
        <f7-button slot="media" @click="confirmTklfh">اعتمد هالتكلفة</f7-button>
      </f7-list-input>
      <f7-list-input
          type="number"
          pattern="[0-9.]+"
          placeholder="صافي ربح جديد"
          @keyup.native="newvals.gain = $event.target.value"
      >
        <f7-button slot="media" @click="confirmGain">اعتمد الربح ذا</f7-button>
      </f7-list-input>
    </f7-list>

  </f7-col>
  <f7-col></f7-col>
</f7-row>
</f7-page>
</template>

<script>
import {api} from "../js/routes";
// {"weight":"0.35","box":{"length":"5.9","width":"0.5","height":"0.5","unit":"inches"},"snsShipping":"58 SAR","snsFlexShipping":"31 SAR"}
export default {
  name: "sns",
  data() {
    return {
      quantity: 30,
      tklfh: 0,
      price_in: 32,
      price_out: 33.8,
      percentage: 0,
      gain: 0,
      newvals: {
        tklfh: 0,
        gain: 0,
      }
    }
  },
  methods: {
    setQuantity(val) {
      this.quantity = val
      this.calculate()
    },
    setPriceIn(val) {
      this.price_in = val
      this.calculate()

    },
    confirmTklfh() {
      let val = this.newvals.tklfh
      if(!val)
      {
        this.$f7.dialog.alert("اكتب سعر تكلفة قبل")
        return
      }
      this.quantity = Math.floor(val/this.price_in)
      this.calculate()
    },
    confirmGain() {
      let val = this.newvals.gain
      if(!val)
      {
        this.$f7.dialog.alert("اكتب الربح قبل")
        return
      }

      let q = 0
      let myTklfh = q * this.price_in

      let out = q * this.price_out
      let myGain = (out - myTklfh)
      while(myGain < val)
      {
        q++
        myTklfh = q * this.price_in
        out = q * this.price_out
        myGain = (out - myTklfh)
      }
      this.quantity = q
      this.calculate()
    },
    setPriceOut(val) {
      this.price_out = val
      this.calculate()
    },
    setPercentage(val) {
      this.percentage = val
    },
    setGain(val) {
      this.gain = val
    },
    calculate() {
      this.tklfh = this.quantity*this.price_in
      let out = this.quantity * this.price_out
      this.gain = (out - this.tklfh).toFixed(2)
      this.percentage = ((this.gain/this.tklfh)*100).toFixed(2)
    }

  },
  mounted() {
    this.calculate()
  },
  computed: {
    getColor() {
      return (this.gain >= 0)?"green":"red"
    },
  }
}
</script>

<style scoped>

</style>
