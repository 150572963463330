<template>
<f7-page>
  <f7-navbar back-link title="الورث"></f7-navbar>
  <f7-toolbar bottom tabbar>
    <f7-link tab-link="#tab-1" tab-link-active>ابوي</f7-link>
    <f7-link tab-link="#tab-2">امي</f7-link>
  </f7-toolbar>
  <f7-tabs>
    <f7-tab id="tab-1" class="page-content" tab-active>
      <f7-block style="direction: rtl">
        <h2>تقسيم الورث من ابوي</h2>
        <f7-list inline-labels no-hairlines-md>
          <f7-list-input
              type="text"
              placeholder="ادخل المبلغ"
              pattern="[0-9]*"
              @input="father_input"
          >
            <f7-icon slot="media">
              <i class="f7-icons size-14">hand_point_left_fill</i>
            </f7-icon>
          </f7-list-input>
        </f7-list>

      </f7-block>
      <f7-block-title class="rtl">التقسيمة</f7-block-title>
      <f7-block strong class="rtl">
        <f7-row>
          <f7-col class="wrth-th">الوقف:</f7-col>
          <f7-col class="wrth-td" v-text="new Intl.NumberFormat('ar', {
  style: 'currency',
  currency: 'SAR',
  minimumFractionDigits: 2
}).format(father.w8f)"></f7-col>
        </f7-row>
        <f7-row>
          <f7-col class="wrth-th">الام:</f7-col>
          <f7-col class="wrth-td" v-text="new Intl.NumberFormat('ar', {
  style: 'currency',
  currency: 'SAR',
  minimumFractionDigits: 2
}).format(father.mother)"></f7-col>
        </f7-row>
        <f7-row>
          <f7-col class="wrth-th">الذكر:</f7-col>
          <f7-col class="wrth-td" v-text="new Intl.NumberFormat('ar', {
  style: 'currency',
  currency: 'SAR',
  minimumFractionDigits: 2
}).format(father.male)"></f7-col>
        </f7-row>
        <f7-row>
          <f7-col class="wrth-th">الانثى:</f7-col>
          <f7-col class="wrth-td" v-text="new Intl.NumberFormat('ar', {
  style: 'currency',
  currency: 'SAR',
  minimumFractionDigits: 2
}).format(father.female)"></f7-col>
        </f7-row>
      </f7-block>
    </f7-tab>
    <f7-tab id="tab-2" class="page-content">
      <f7-block style="direction: rtl">
        <h2>تقسيم الورث من امي</h2>

        <f7-list inline-labels no-hairlines-md>
          <f7-list-input
              type="text"
              placeholder="ادخل المبلغ"
              pattern="[0-9]*"
              @input="mother_input"
              :value="mother.total"
          >
            <f7-icon slot="media">
              <i class="f7-icons size-14">hand_point_left_fill</i>
            </f7-icon>
          </f7-list-input>
        </f7-list>

      </f7-block>
      <f7-block-title class="rtl">التقسيمة</f7-block-title>
      <f7-block strong class="rtl">
        <f7-row>
          <f7-col class="wrth-th">الذكر:</f7-col>
          <f7-col class="wrth-td" v-text="new Intl.NumberFormat('ar', {
  style: 'currency',
  currency: 'SAR',
  minimumFractionDigits: 2
}).format(mother.male)"></f7-col>
        </f7-row>
        <f7-row>
          <f7-col class="wrth-th">الانثى:</f7-col>
          <f7-col class="wrth-td" v-text="new Intl.NumberFormat('ar', {
  style: 'currency',
  currency: 'SAR',
  minimumFractionDigits: 2
}).format(mother.female)"></f7-col>
        </f7-row>
      </f7-block>
    </f7-tab>
  </f7-tabs>
</f7-page>
</template>

<script>
export default {
  name: "wrth",
  data() {
    return {
      father: {
        total: 0,
        w8f: 0,
        mother: 0,
        male: 0,
        female: 0
      },
      mother: {
        total: 0,
        male: 0,
        female: 0,
      },

    }
  },
  methods: {
    father_input(e) {
      let total = e.target.value/1
      this.father.total = total

      let w8f = total/4
      total -= w8f
      this.father.w8f = w8f

      let mother = total/8
      total -= mother
      this.father.mother = mother
      this.mother.total = mother
      this.calc_mother(mother)

      this.father.female = total/38

      this.father.male = this.father.female*2

    },
    mother_input(e) {
      let total = e.target.value/1
      this.calc_mother(total)
    },
    calc_mother(total) {
      this.mother.total = total

      this.mother.female = total/22

      this.mother.male = this.mother.female*2

    }

  }
}
</script>

<style scoped>

</style>