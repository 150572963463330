<template>
  <f7-page name="calculations">
    <f7-navbar title="حسابات الطريق" back-link="Back"></f7-navbar>
    <f7-block strong>
      <f7-block-title>حسب الوقت او المسافة او السرعة</f7-block-title>
      <f7-block strong>
        <f7-row>
          <f7-col class="text-align-center">
            <f7-gauge
                    type="semicircle"
                    :value="(speed/240)"
                    :value-text="speed+' كم/س'"
                    value-text-color="#f44336"
                    border-color="#f44336"
                    label-text="السرعة"
                    label-text-color="#333"
            ></f7-gauge>
          </f7-col>
          <f7-col class="text-align-center">
            <f7-gauge
                    type="semicircle"
                    :value="(dist/1000)"
                    :value-text="dist+' كيلو'"
                    value-text-color="#e91e63"
                    border-color="#e91e63"
                    label-text="المسافة"
                    label-text-color="#333"
            ></f7-gauge>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col class="text-align-center" style="font-size: 2em">
            الوقت: <span v-text="hours"></span>:<span v-text="minutes"></span>:<span v-text="seconds"></span>
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col>
            <f7-block-title>السرعة</f7-block-title>
            <f7-block strong>
              <f7-range
                      :min="0"
                      :max="240"
                      :label="true"
                      :step="1"
                      v-bind:value="speed"
                      :scale="true"
                      :scale-steps="5"
                      :scale-sub-steps="4"
                      @range:change="bySpeed"
              ></f7-range>
            </f7-block>

          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col>
            <f7-block-title>المسافة</f7-block-title>
            <f7-block strong>
              <f7-range
                      :min="0"
                      :max="1000"
                      :label="true"
                      :step="1"
                      v-bind:value="dist"
                      :scale="true"
                      :scale-steps="5"
                      :scale-sub-steps="4"
                      @range:change="byDistance"
              ></f7-range>
            </f7-block>

          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col>
            <f7-block-title>الوقت</f7-block-title>
            <f7-block strong>
              <f7-range
                      :min="0"
                      :max="14"
                      :label="true"
                      :step="0.1"
                      v-bind:value="time"
                      :scale="true"
                      :scale-steps="5"
                      :scale-sub-steps="4"
                      @range:change="byTime"
              ></f7-range>
            </f7-block>

          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col>
            <f7-button @click="calculate(false, false)"class="button-outline">احسب</f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-block>
  </f7-page>
</template>
<script>
  export default {
    data() {
      return {
        speed: 120,
        dist: 100,
        time: 40,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    },
    methods: {
      calculate(spd, dst) {
        if(spd!==false)
          this.speed = spd;
        if(dst !== false)
          this.dist = dst;
        this.time = this.dist/this.speed;
        let hours = parseInt(this.time)
        let minutes = (this.time-hours)*60
        let minutesI = parseInt(minutes)
        let seconds = Math.ceil((minutes-minutesI)*60)

        //console.log(`Time: ${hours}:${minutesI}:${seconds}`)
        this.hours = hours; this.minutes = minutesI; this.seconds = seconds;
      },
      calculateByTime() {
        let minutes = this.minutes/60;
        let seconds = this.seconds/60/60;
        this.time = this.hours+minutes+seconds;
        this.speed = (this.dist/this.time).toFixed(2);

      },
      bySpeed(val) {
        this.calculate(val, false);
      },
      byDistance(val) {
        this.calculate(false, val);
      },
      byTime(val) {
        this.time = val;
        this.hours = parseInt(this.time)
        let minutes = (this.time-this.hours)*60
        this.minutes = parseInt(minutes)
        this.seconds = Math.ceil((minutes-this.minutes)*60)

        this.calculateByTime();
      }
    }
  };
</script>
